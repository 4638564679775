import React from "react";
import PButton from "./buttons/PButton";

export type PPaginationProps = {
  startIndex: number;
  pageSize: number;
  totalCount: number;
  onBackClicked?: (() => void) | null;
  onNextClicked?: (() => void) | null;
  disabled?: boolean;
};

const PPaginationComponent = (props: PPaginationProps) => {
  const {
    startIndex,
    pageSize,
    totalCount,
    onBackClicked,
    onNextClicked,
    disabled,
  } = props;

  const topCount = Math.min(totalCount, startIndex + pageSize);
  const startCount = Math.min(totalCount, startIndex + 1);
  const resultWord = totalCount === 1 ? "result" : "results";

  return (
    <nav
      aria-label="Pagination"
      className="flex items-center justify-between bg-p-white gap-2"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-p-black-lighter">
          Showing <span className="font-medium">{startCount}</span> to{" "}
          <span className="font-medium">{topCount}</span> of{" "}
          <span className="font-medium">{totalCount}</span> {resultWord}
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end gap-2">
        {onBackClicked ? (
          <PButton onClick={onBackClicked} kind="secondary" disabled={disabled}>
            Previous
          </PButton>
        ) : null}
        {onNextClicked ? (
          <PButton onClick={onNextClicked} kind="secondary" disabled={disabled}>
            Next
          </PButton>
        ) : null}
      </div>
    </nav>
  );
};

PPaginationComponent.defaultProps = {
  onBackClicked: null,
  onNextClicked: null,
  disabled: false,
};

export default PPaginationComponent;
