import React, { useEffect, useState } from "react";
import PWizardScreen from "../../../ui/screens/wizards/PWizardScreen";
import PLoadingScreen from "../../../ui/screens/PLoadingScreen";
import { components } from "../../../../api/spec";
import {
  useActivePageTime,
  useBusyWatcher,
  useUniqueId,
} from "../../../../util/hooks";
import {
  getQuizRecipient,
  markRecipientAsOpened,
  recordParticipation,
} from "../../../../api/reflection/quizzes";
import QuizNotFoundScreen from "./QuizNotFoundScreen";
import QuizAlreadyCompletedScreen from "./QuizAlreadyCompletedScreen";
import QuizWelcomeScreen from "./QuizWelcomeScreen";
import QuizCompletionScreen from "./QuizCompletionScreen";
import EmbedTypeformScreen from "./EmbedTypeformScreen";
import { backgroundGradient, middleCardSpacing } from "../../../../util/style";
import { classNames } from "../../../../util/strings";
import { PWizardScreenPanel } from "../../../ui/screens/wizards/common";

type EmbedTypeformPageProps = {
  recipient: string;
  createQuizUrl: string;
};

enum EmbedTypeformStage {
  LOADING,
  WELCOME,
  RESPOND,
  COMPLETED,
  ALREADY_COMPLETED,
  NOT_FOUND,
}

const EmbedTypeformPage = (props: EmbedTypeformPageProps) => {
  const { recipient, createQuizUrl } = props;

  const sessionId = useUniqueId();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [recipientContent, setRecipientContent] = useState<
    components["schemas"]["GetRecipientResponseSerializerWrapper"] | null
  >(null);
  const [stage, setStage] = useState<EmbedTypeformStage>(
    EmbedTypeformStage.LOADING,
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [_, busyWatcher] = useBusyWatcher();

  const submitParticipation = async (
    durationSeconds: number,
    isSubmission: boolean,
  ) => {
    await recordParticipation({
      recipient,
      session: sessionId,
      duration_s: durationSeconds,
      is_submission: isSubmission,
    });
  };

  const getActivePageTime = useActivePageTime(async (totalSeconds: number) => {
    if (isCompleted) {
      return;
    }
    await submitParticipation(totalSeconds, false);
  });

  const fetchRecipient = async (): Promise<
    [
      components["schemas"]["GetRecipientResponseSerializerWrapper"] | null,
      components["schemas"]["BadInputResponseWrapper"] | null,
    ]
  > => {
    const [newRecipient, newErrors] = await getQuizRecipient({ recipient }, [
      busyWatcher,
    ]);
    if (newErrors !== null) {
      setErrors([newErrors.message!]);
    }
    if (newRecipient !== null) {
      setRecipientContent(newRecipient);
    }
    return [newRecipient, newErrors];
  };

  const goToStage = async (nextStage: EmbedTypeformStage) => {
    setErrors([]);
    // eslint-disable-next-line default-case,no-empty
    switch (nextStage) {
      case EmbedTypeformStage.WELCOME:
        await markRecipientAsOpened({ recipient }, [busyWatcher]);
    }
    setStage(nextStage);
  };

  const getRecipientName = (): string | null => {
    if (recipientContent === null) {
      return null;
    }
    return `${recipientContent.content.first_name} ${recipientContent.content.last_name}`.trim();
  };

  const loadPage = async () => {
    const [newRecipient, __] = await fetchRecipient();
    if (newRecipient === null) {
      return;
    }
    if (!newRecipient.content.does_quiz_exist) {
      await goToStage(EmbedTypeformStage.NOT_FOUND);
      return;
    }
    if (newRecipient.content.is_response_complete) {
      await goToStage(EmbedTypeformStage.ALREADY_COMPLETED);
      return;
    }
    await goToStage(EmbedTypeformStage.WELCOME);
  };

  const onQuizCompleted = async () => {
    setIsCompleted(true);
    await goToStage(EmbedTypeformStage.COMPLETED);
    await submitParticipation(getActivePageTime(), true);
  };

  const getElements = (): PWizardScreenPanel[] => [
    {
      stage: EmbedTypeformStage.LOADING,
      content: (
        <div className={middleCardSpacing}>
          <PLoadingScreen
            errors={errors}
            onBackClicked={() => window.history.back()}
          />
        </div>
      ),
    },
    {
      stage: EmbedTypeformStage.NOT_FOUND,
      content: recipientContent && (
        <div className={middleCardSpacing}>
          <QuizNotFoundScreen recipientName={getRecipientName()!} />
        </div>
      ),
    },
    {
      stage: EmbedTypeformStage.ALREADY_COMPLETED,
      content: recipientContent &&
        recipientContent.content &&
        recipientContent.content.quiz !== null && (
          <div className={middleCardSpacing}>
            <QuizAlreadyCompletedScreen
              recipientName={getRecipientName()!}
              quizTitle={recipientContent.content.quiz.title!}
            />
          </div>
        ),
    },
    {
      stage: EmbedTypeformStage.WELCOME,
      content: recipientContent &&
        recipientContent.content &&
        recipientContent.content.quiz !== null && (
          <div className={middleCardSpacing}>
            <QuizWelcomeScreen
              recipientName={getRecipientName()!}
              quizTitle={recipientContent.content.quiz.title!}
              quizDescription={recipientContent.content.quiz.description!}
              timeEstimate={recipientContent.content.time_estimate!}
              onNextClicked={() => goToStage(EmbedTypeformStage.RESPOND)}
            />
          </div>
        ),
    },
    {
      stage: EmbedTypeformStage.RESPOND,
      content: recipientContent &&
        recipientContent.content &&
        recipientContent.content.quiz !== null && (
          <EmbedTypeformScreen
            typeformId={recipientContent.content.quiz.typeform_id!}
            recipient={recipient}
            onFormCompleted={onQuizCompleted}
          />
        ),
    },
    {
      stage: EmbedTypeformStage.COMPLETED,
      content: recipientContent && (
        <div className={middleCardSpacing}>
          <QuizCompletionScreen
            recipientName={getRecipientName()!}
            createQuizUrl={createQuizUrl}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    loadPage();
  }, []);

  return (
    <div className={classNames(backgroundGradient, "min-h-full")}>
      <PWizardScreen elements={getElements()} stage={stage} />
    </div>
  );
};

export default EmbedTypeformPage;
