import React, { useState } from "react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { components } from "../../../../api/spec";
import PCard from "../../../ui/PCard";
import PTitle from "../../../ui/PTitle";
import PBadge from "../../../ui/PBadge";
import { QuestionTitleFromType } from "../../quizzes/QuizQuestionCard";
import { useReportQuestionDialog } from "./hooks";
import { classNames } from "../../../../util/strings";

type ReportBackgroundCardProps = {
  report: components["schemas"]["GetQuizReportResponseSerializerWrapper"];
  showParticipants?: boolean;
};

const ReportBackgroundCardComponent = (props: ReportBackgroundCardProps) => {
  const { report, showParticipants: showParticipantsInput } = props;

  const [showQuestions, setShowQuestions] = useState<boolean>(false);
  const [showParticipants, setShowParticipants] = useState<boolean>(false);
  const { dialog, showQuestion } = useReportQuestionDialog();

  const getKeyForParticipant = (
    participant: components["schemas"]["QuizParticipant"],
  ): string =>
    `${participant.first_name} ${participant.last_name} ${participant.email}`;

  const getNameForParticipant = (
    participant: components["schemas"]["QuizParticipant"],
    index: number,
  ): string => {
    if (
      participant.last_name === null &&
      participant.first_name === null &&
      participant.email === null
    ) {
      return `Participant ${index}`;
    }
    let participantName = `${participant.first_name} ${participant.last_name}`;
    if (participant.email !== null) {
      participantName = `${participantName} (${participant.email})`;
    }
    return participantName;
  };

  return (
    <>
      <PCard>
        <div className="flex flex-col gap-3">
          <PTitle className="whitespace-pre" title="Background" />
          <button
            type="button"
            onClick={() => setShowQuestions(!showQuestions)}
          >
            <div className="flex flex-row items-center gap-3 hover:text-primary">
              {showQuestions ? (
                <ChevronDownIcon className="w-6 h-6" />
              ) : (
                <ChevronRightIcon className="w-6 h-6" />
              )}
              <div>
                Questions asked ({report.content.report.questions_asked.length})
              </div>
            </div>
          </button>
          {showQuestions ? (
            <div className="flex flex-col gap-2 sm:gap-1 text-sm">
              {report.content.report.questions_asked.map((question) => (
                <button
                  type="button"
                  key={question.question.title}
                  onClick={() => showQuestion({ question })}
                >
                  <div
                    className="bg-secondary/20 sm:bg-white grid grid-cols-1 gap-2 sm:grid-cols-5 p-1 hover:bg-primary/20 rounded-lg"
                    key={question.question.title}
                  >
                    <div className="col-span-1 sm:col-span-4 flex items-center justify-start text-left">
                      {question.question.title}
                    </div>
                    <div className="col-span-1 flex items-center justify-start sm:justify-end gap-2">
                      <PBadge
                        content={QuestionTitleFromType(
                          question.question.question_type,
                        )}
                      />
                      <div className="whitespace-pre">
                        ({question.num_respondents} /{" "}
                        {report.content.report.participants.length} responses)
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          ) : null}
          {showParticipantsInput ? (
            <>
              <button
                type="button"
                onClick={() => setShowParticipants(!showParticipants)}
              >
                <div className="flex flex-row items-center gap-3 hover:text-primary">
                  {showParticipants ? (
                    <ChevronDownIcon className="w-6 h-6" />
                  ) : (
                    <ChevronRightIcon className="w-6 h-6" />
                  )}
                  <div>
                    Participants invited (
                    {
                      report.content.report.participants.filter(
                        (p) => p.is_completed,
                      ).length
                    }{" "}
                    / {report.content.report.participants.length})
                  </div>
                </div>
              </button>
              {showParticipants ? (
                <div className="flex flex-col text-sm">
                  {report.content.report.participants.map(
                    (participant, index) => (
                      <div
                        key={getKeyForParticipant(participant)}
                        className={classNames(
                          index % 2 === 0
                            ? "bg-p-white"
                            : "bg-primary-lightest/10",
                          "flex flex-row items-center gap-3 justify-between p-1",
                        )}
                      >
                        <div className="text-left">
                          {getNameForParticipant(participant, index)}
                        </div>
                        <div>
                          <PBadge
                            className="min-w-24"
                            bgClass={
                              participant.is_completed
                                ? "bg-success"
                                : "bg-danger text-p-white"
                            }
                            content={
                              participant.is_completed
                                ? "responded"
                                : "no response"
                            }
                          />
                        </div>
                      </div>
                    ),
                  )}
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </PCard>
      {dialog}
    </>
  );
};

ReportBackgroundCardComponent.defaultProps = {
  showParticipants: true,
};

export default ReportBackgroundCardComponent;
