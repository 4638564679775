import React from "react";
import { components } from "../../../../api/spec";
import PErrors from "../../../ui/input/PErrors";
import PromptSummaryList from "./PromptSummaryList";
import PPagination from "../../../ui/PPagination";
import PSearchBox from "../../../ui/input/PSearchBox";

type ListPromptsScreenProps = {
  errors: string[];
  offset: number;
  limit: number;
  prompts:
    | components["schemas"]["ListLLMPromptsResponseSerializerWrapper"]
    | null;
  disabled: boolean;
  onBackButtonClicked?: (() => void) | null;
  onNextButtonClicked?: (() => void) | null;
  searchTerm?: string | null;
  onSearchUpdated?: ((search: string | null) => void) | null;
  onPromptClicked?:
    | ((prompt: components["schemas"]["LLMPromptSummary"]) => void)
    | null;
};

const ListPromptsScreenComponent = (props: ListPromptsScreenProps) => {
  const {
    errors,
    offset,
    limit,
    prompts,
    disabled,
    onBackButtonClicked,
    onNextButtonClicked,
    searchTerm,
    onSearchUpdated,
    onPromptClicked,
  } = props;

  if (errors.length > 0) {
    return <PErrors errors={errors} />;
  }

  const hasBackButton = offset > 0;
  const hasNextButton =
    prompts !== null && prompts.content.count > offset + limit;
  const hasTopBar = Boolean(onSearchUpdated);

  return (
    <div className="flex flex-col gap-3">
      {hasTopBar ? (
        <div className="flex flex-row items-center">
          {onSearchUpdated ? (
            <PSearchBox
              searchTerm={searchTerm}
              onSearchTermUpdated={onSearchUpdated}
              placeholder="search prompts"
            />
          ) : null}
        </div>
      ) : null}
      <PromptSummaryList
        prompts={prompts?.content.prompts ?? []}
        loading={prompts === null}
        disabled={disabled}
        onClick={onPromptClicked}
      />
      {prompts !== null && prompts.content.prompts.length > 0 ? (
        <PPagination
          startIndex={offset}
          pageSize={limit}
          totalCount={prompts.content.count}
          disabled={disabled}
          onBackClicked={hasBackButton ? onBackButtonClicked : null}
          onNextClicked={hasNextButton ? onNextButtonClicked : null}
        />
      ) : null}
    </div>
  );
};

ListPromptsScreenComponent.defaultProps = {
  onBackButtonClicked: null,
  onNextButtonClicked: null,
  searchTerm: null,
  onSearchUpdated: null,
  onPromptClicked: null,
};

export default ListPromptsScreenComponent;
