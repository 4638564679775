import React from "react";
import { classNames } from "../../util/strings";

type PSubtextProps = {
  text?: string | null;
  className?: string | null;
};

const PSubtextComponent = (props: PSubtextProps) => {
  const { text, className } = props;

  return text ? (
    <div className={classNames("text-xs", className)}>{text}</div>
  ) : (
    <div className="h-6" />
  );
};

PSubtextComponent.defaultProps = {
  text: null,
  className: null,
};

export default PSubtextComponent;
