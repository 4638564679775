// The wrapper container that center forms should have
export const middleFormWrapper =
  "flex min-h-full flex-col justify-center px-4 py-12 lg:px-6";

// The inner container that center forms should have
export const middleFormContent = "sm:mx-auto sm:w-full sm:max-w-sm";

// Wrapper for full page content that should fit in the middle of the page, not worrying
// about margins underneath the nav bar
export const middleColumnWrapperNoTopMargin =
  "flex min-h-full flex-col flex-1 justify-center";

export const standardGutters = "mx-auto max-w-7xl px-2 sm:px-4 lg:px-8";

export const middleColumnWrapper = middleColumnWrapperNoTopMargin;

export const middleColumnContent = "sm:mx-auto sm:w-full sm:max-w-lg";

export const labelText = "block text-sm font-medium leading-3 text-m-dark-gray";

export const clickableText =
  "text-primary hover:text-primary-lighter hover:underline cursor-pointer not-italic font-semibold";

export const backgroundGradient = "bg-gradient-to-r from-primary to-secondary";

export const middleCardSpacing = "mt-4 mx-4 sm:mt-20 sm:mx-0";
